import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useMeetingroomsList() {
  // Use toast
  const toast = useToast()

  const refMeetingroomsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'nome', sortable: true },
    { key: 'posti', sortable: true },
    { key: 'sede', sortable: true },
    { key: 'azioni' },
  ]
  const fields = {
    id: 'id',
    nome: 'name',
    posti: 'max_persons',
    sede: 'headquarter',
  }

  const perPage = ref(10)
  const totalMeetingrooms = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refMeetingroomsListTable.value ? refMeetingroomsListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalMeetingrooms.value,
    }
  })

  const refetchData = () => {
    refMeetingroomsListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchMeetingrooms = (ctx, callback) => {
    store
      .dispatch('app-meetingrooms/fetchMeetingrooms', {
        text_search: searchQuery.value,
        per_page: perPage.value,
        page: currentPage.value - 1,
        sort_by: fields[sortBy.value],
        sort_direction: isSortDirDesc.value === true ? 'DESC' : 'ASC',
        include_deactivated_headquarters: true,
      })
      .then(response => {
        const meetingrooms = response.data[0]
        const total = response.data[1]

        callback(meetingrooms)
        totalMeetingrooms.value = total
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchMeetingrooms,
    tableColumns,
    perPage,
    currentPage,
    totalMeetingrooms,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refMeetingroomsListTable,

    refetchData,
  }
}
